html,
body {
  height: 100%;
}

html {
  color: $color-text;
  background: $color-bg;
}

body {
  margin: 0px !important;
  min-height: 100vh;
  font-family: 'rustica,sans-serif';
}

img {
  max-width: 100%;
}

.vertical-timeline::before {
  background-color: 'red';
}

.react-colorful__last-control {
  border-radius: 0 !important;
}



/* arrows */

select.classic {
    /* styling */
      background-color: white;
      border: thin solid black;
      border-radius: 4px;
      display: inline-block;
      font: inherit;
      line-height: 1.5em;
      padding: 0.5em 3.5em 0.5em 1em;
    
      /* reset */
    
      margin: 0;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      -webkit-appearance: none;
      -moz-appearance: none;

  background-image:
    linear-gradient(45deg, transparent 50%, black 50%),
    linear-gradient(135deg, black 50%, transparent 50%);
  background-position:
    calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px),
    100% 0;
  background-size:
    5px 5px,
    5px 5px,
    2.5em 2.5em;
  background-repeat: no-repeat;
}

select.classic:focus {
  background-image:
    linear-gradient(45deg, black 50%, transparent 50%),
    linear-gradient(135deg, transparent 50%, black 50%);
  background-position:
    calc(100% - 15px) 1em,
    calc(100% - 20px) 1em,
    100% 0;
  background-size:
    5px 5px,
    5px 5px,
    2.5em 2.5em;
  background-repeat: no-repeat;
  border-color: grey;
  outline: 0;
}
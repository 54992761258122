$color-white: #fff;
$color-black: #000;

//default theme
$color-text: #313131;
$color-bg: #ffffff;
$color-dark: #313131;
$color-form-bg: #ffffff;
$color-btn-default: #201ce0;
$color-green: #8ffaf3;
$color-grey: #82828d;

//chart arrows
$color-rise: #8ffac0;
$color-drop: #ff7e96;

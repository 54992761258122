@media screen and (max-width: 1344px) {
  html,
  body {
    font-size: 80%;
  }
}

a {
  text-decoration: none !important;
  color: #313131 !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
  margin-block-start: 0px;
  margin-block-end: 0px;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

img {
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.img-holder {
  margin-top: 1rem;
  cursor: pointer;
}

[type='file'] {
  display: none;
}

.MuiListItemText-primary.MuiTypography-body1 {
  font-weight: 700;
}

.MuiListItem-root.Mui-selected {
  color: unset !important;
  background-color: unset !important;
}

.MuiMenuItem-root.Mui-selected {
  color: #201ce0 !important;
  // background-color: unset !important;
  // $svg rect: { fill: theme.palette.primary.contrastText };
  path {
    fill: #201ce0;
  }
}

.MuiSlider-mark {
  position: relative !important;
}

body.swal2-toast-shown .swal2-container.swal2-center {
  top: 68px !important;
  font-family: rustica, sans-serif !important;
}
.swal2-popup.swal2-toast{
  padding: 4px;
  padding-inline: 8px;
}
.swal2-title{
  font-size: 12;
  font-weight: 400;
}
.swal2-container {
  z-index: 10000 !important;
}

.MuiAccordion-root:first-of-type {
  border-radius: 10px;
}

.MuiAccordion-root:last-of-type {
  border-radius: 10px;
}

.MuiSelect-icon {
  color: #b6b6b6;
}

.CircularProgressbar-text {
  font-weight: 700;
  font-family: rustica, sans-serif !important;
}

/* @font-face {
  font-family: 'rustica,sans-serif';
  src: local('HKGrotesk'), url(./fonts/HKGrotesk-Regular.otf) format('opentype');
}

@font-face {
  font-family: 'rustica,sans-serif';
  font-weight: 700;
  src: local('HKGrotesk'), url(./fonts/HKGrotesk-Bold.otf) format('opentype');
} */

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');

#joystick {
  display: grid;
  grid-template-rows: repeat(3, 20px);
  grid-template-columns: repeat(3, 20px);
  grid-template-areas:
      ". up ."
      "left center right"
      ". down .";
  cursor: "auto" !important
}